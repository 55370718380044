<template>
  <div class="coupons" :class="{skeleton: !state.loaded}">
    <div class="header">
      <div class="left">
        <span>총 {{ $lib.getNumberFormat(state.total) }}건</span>
      </div>
      <div class="right">
        <select class="form-control" v-model="state.args.key">
          <option value="couponName">쿠폰명</option>
          <option value="couponId">쿠폰 아이디</option>
        </select>
        <label class="search">
          <input type="text" class="form-control" placeholder="값을 입력해주세요" v-model="state.args.keyword" @keyup.enter="pushToRouter(state.args.key, state.args.keyword)">
          <span class="remove" @click="removeKeyword(state.args.key)" v-if="state.args.keyword">&times;</span>
        </label>
        <button class="btn btn-bordered-secondary" @click="pushToRouter(state.args.key, state.args.keyword)">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
    <div class="table-responsive" v-if="state.coupons.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>
            <span>쿠폰 아이디</span>
          </th>
          <th>
            <span>쿠폰명</span>
          </th>
          <th>
            <span>현황</span>
            <div class="dropdown">
              <i class="fa fa-question-circle" type="button" :id="`${component.name}Statement`" data-toggle="dropdown" aria-haspopup="true"></i>
              <div class="dropdown-menu" :aria-labelledby="`${component.name}Statement`">
                <div class="section">
                  <b>쿠폰수</b>
                  <span>생성: 쿠폰발급관리에서 생성한 쿠폰번호 수</span>
                  <span>전환: 회원이 전환한 쿠폰번호 수</span>
                  <span>잔여: 잔여 쿠폰번호 수</span>
                </div>
                <div class="section">
                  <b>금액</b>
                  <span>생성: 포인트 금액 합</span>
                  <span>전환: 회원이 전환한 포인트 금액 합</span>
                  <span>잔여: 사용한 포인트 금액 합</span>
                </div>
                <div class="section">
                  <b>프로젝트수</b>
                  <span>적용대상: 쿠폰의 적용대상 프로젝트 수</span>
                  <span>사용: 사용한 프로젝트 수</span>
                </div>
                <div class="section">
                  <b>회원수</b>
                  <span>전환: 쿠폰을 입력하여 포인트로 전환한 회원 수</span>
                  <span>참여(예약): 참여(예약)한 회원 수</span>
                </div>
              </div>
            </div>
          </th>
          <th>
            <span>사용 가능일</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(c,idx) in state.coupons" :key="idx">
          <td>
            <span>{{ c.couponId }}</span>
          </td>
          <td>
            <router-link :to="`/new-admin/coupons/${c.couponId}`" class="color-anchor pointer">{{ c.couponName }}</router-link>
          </td>
          <td>
            <div class="statement">
              <div class="item">
                <div class="title">
                  <b>쿠폰수</b>
                  <div class="desc">
                    <span>생성 / 전환 / 잔여</span>
                  </div>
                </div>
                <span>{{ $lib.getNumberFormat(c.totalCouponCount) }} / {{ $lib.getNumberFormat(c.assignCouponCount) }} / {{ $lib.getNumberFormat(c.totalCouponCount - c.assignCouponCount) }}</span>
              </div>
              <div class="item">
                <div class="title">
                  <b>금액</b>
                  <div class="desc">
                    <span>생성 / 전환 / 사용</span>
                  </div>
                </div>
                <span>{{ $lib.getNumberFormat(c.totalCouponSum) }} / {{ $lib.getNumberFormat(c.assignCouponSum) }} / {{ $lib.getNumberFormat(c.assignCouponSum - c.restCouponSum) }}</span>
              </div>
              <div class="item">
                <div class="title">
                  <b>프로젝트 수</b>
                  <div class="desc">
                    <span>적용 대상 / 사용</span>
                  </div>
                </div>
                <span>{{ c.scopeProjectCount }} / {{ c.usedProjectCount }}</span>
              </div>
              <div class="item">
                <div class="title">
                  <b>회원수</b>
                  <div class="desc">
                    <span>전환 / 참여(예약)</span>
                  </div>
                </div>
                <span>{{ c.assignCouponMemberCount }} / {{ c.usedMemberCount }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="wrapper" v-if="c.useYn === 'Y'">
              <span>{{ $lib.getDateFormat(c.usableStartDate, "yyyy-MM-dd") }}</span>
              <span> ~ </span>
              <span>{{ $lib.getDateFormat(c.usableEndDate, "yyyy-MM-dd") }}</span>
            </div>
            <div class="wrapper" v-else>
              <span>비활성화됨</span>
              <button class="btn btn-secondary" @click="remove(c.couponId, c.couponName)">삭제</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <NoData v-else/>
    <PaginationJpa className="btn-primary" :change="load" :total="state.total" :pageable="state.pageable" v-if="state.pageable.size && state.loaded"/>
    <div class="add" v-if="state.loaded">
      <router-link to="/new-admin/coupons/create">
        <span>쿠폰 생성</span>
        <i class="fa fa-plus"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";
import PaginationJpa from "@/components/PaginationJpa.vue";
import lib from "../../../scripts/lib";
import http from "@/scripts/http";
import store from "@/scripts/store";
import NoData from "@/components/NoData.vue";

function Component(initialize) {
  this.name = "pagesAdminCouponCoupons";
  this.initialize = initialize;
}

export default defineComponent({
  computed: {
    lib() {
      return lib;
    }
  },
  components: {NoData, PaginationJpa},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      coupons: [],
      loaded: false,
      total: 0,
      pageable: {
        size: 0,
        page: 0,
      },
      args: {
        key: "couponName",
        keyword: "",
      },
    });

    const load = (page) => {
      const args = {
        page: page || 0,
        size: 50
      };

      for (let i in router.app.$route.query) {
        router.app.$route.query[i] && (args[i] = router.app.$route.query[i]);
      }

      for (let i = 1; i < 6; i += 1) {
        state.coupons.push({
          couponId: `C00000${i}`,
          couponName: `테스트 쿠폰${i}`,
          couponCount: i,
          pointAmt: i + "00000",
          projectCount: i,
          memberCount: i,
          usableDate: `2024-06-${i}~2024-06-${i + 10}`
        });
      }

      state.loaded = false;
      http.get(`/api/admin/coupons`, args).then(({data}) => {
        state.loaded = true;
        state.coupons = data.body.content;
        state.pageable = data.body.pageable;
        state.total = data.body.total;
      });
    };

    const pushToRouter = (key, value) => {
      const query = lib.getRenewed(router.app.$route.query);

      query[key] = value;

      if (key === "couponId") {
        delete query.couponName;
      } else if (key === "couponName") {
        delete query.couponId;
      }

      router.push({query});
    };

    const removeKeyword = (key) => {
      const query = lib.getRenewed(router.app.$route.query);
      state.args.keyword = "";

      if (!query[key]) {
        return;
      }

      delete query[key];
      router.push({query});
    };

    const remove = (couponId, couponName) => {
      store.commit("confirm", {
        message: `${couponName} 쿠폰을 정말 삭제하시겠습니까?`,
        allow() {
          http.delete(`/api/admin/coupons/${couponId}`).then(() => {
            store.commit("setSwingMessage", "쿠폰이 삭제되었습니다.");
            load(0);
          });
        }
      });
    };

    watch(() => [
      router.app.$route.query.couponId
      , router.app.$route.query.couponName
    ], () => {
      load(0);
    });

    return {component, state, load, pushToRouter, remove, removeKeyword};
  }
});
</script>

<style lang="scss" scoped>
.coupons {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $px16;

    > .right {
      display: flex;
      gap: $px8;

      .form-control {
        font-size: $px14;
      }

      .search {
        position: relative;
        margin: 0;
        flex-shrink: 0;

        .form-control {
          padding-right: $px30;
        }

        .remove {
          cursor: pointer;
          position: absolute;
          top: 50%;
          right: $px16;
          transform: translateY(-50%);
          padding: $px4;
        }
      }

      > .btn {
        border-color: $colorBorder;
      }
    }
  }

  .table-responsive {
    .table tr {
      th {
        .dropdown {
          padding-left: $px4;

          i {
            font-size: $px13;
          }

          .dropdown-menu {
            padding: $px16;
            font-size: $px12;

            .section {
              b {
                display: block;
                padding-bottom: $px4;
              }

              > span {
                font-weight: 400;
                display: block;
                white-space: nowrap;

                .title {

                }
              }

              &:not(:last-child) {
                padding-bottom: $px16;
              }
            }
          }
        }
      }

      td {
        > .statement {
          display: flex;
          flex-wrap: wrap;
          gap: $px16;

          > .item {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .title {
              display: flex;
              align-items: center;
              gap: $px4;

              .desc {
                color: $colorSecondary;
                font-size: $px11;
              }
            }
          }
        }

        > .wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > .btn {
            font-size: $px12;
            padding: $px4 $px12;
          }
        }
      }
    }
  }

  .add {
    background: $colorPurple;
    bottom: $px30;
    border-radius: $px100;
    color: #fff;
    cursor: pointer;
    padding: $px8 $px16;
    margin-top: $px16;
    width: fit-content;
    left: 100%;
    position: sticky;
    text-align: center;
    transition: background-color 0.18s;
    z-index: 110;

    .fa-plus {
      padding-left: $px4;
      vertical-align: middle;
    }

    &:hover {
      background: $colorPurpleActive;
    }
  }

  .pagination {
    margin-top: $px16;
  }

  &.skeleton {
    .header {
      .left {
        span {
          @include skeleton;
        }
      }

      .right {
        .form-control, .btn {
          @include skeleton;
        }
      }
    }

    .table-responsive .table tr {
      th, td {
        span, .btn, i, b, a {
          @include skeleton;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: $px8;

      .right {
        flex-wrap: wrap;

        select {
          width: auto;
        }
      }
    }
  }
}
</style>